<template>
  <div class="wave-table">
    <div class="action-bar">
      <div class="controls-bar-left">
        <b-button v-if="selectedRows.length ===1 && !program.sfid" size="sm" variant="outline-light"
                  @click="duplicateWavesAction" v-b-tooltip.hover title="Duplicate">
          <IconDuplicate/>
          <b-spinner v-if="isDuplicatingWaves" variant="outline-primary" small />
        </b-button>
        <b-button v-if="selectedRows.length && !program.sfid" size="sm" variant="outline-light"
                  @click="archiveWavesAction" v-b-tooltip.hover title="Archive">
          <IconArchive/>
          <b-spinner v-if="isArchivingWaves" variant="outline-primary" small />
        </b-button>
        <div class="wave-number" v-if= "selectedRows.length === 1 && program.waves.length === 1 && !program.sfid">
          <b-form-input v-model="waveNumber" class="input-number" v-b-tooltip.hover title="Edit wave #" size='sm' type="number" min="0"
                        @keydown.esc="cancelEditWaveNumber"
                        @keydown.enter="submitEditWaveNumber"/>
          <span class="icon-controls">
            <IconCheck class='icon icon-check' @click="submitEditWaveNumber"/>
            <IconCross class='icon icon-cross' @click="cancelEditWaveNumber"/>
          </span>
        </div>
      </div>
    </div>
    <div v-if="tableData.length === 0" class="empty-table">No waves to show</div>
    <ve-table
        v-else
        :columns="columns"
        :table-data="tableData"
        row-key-field-name="id"
        :checkbox-option="tableCheckboxHandlers"
        :sort-option="sortOption"
        :cellSelectionOption="{ enable: false }"
    />
  </div>
</template>

<script>
import IconArchive from '@/assets/svg/Icon-archive.svg'
import IconDuplicate from '@/assets/svg/Icon-duplicate.svg'
import IconSync from '@/assets/svg/Icon-sync.svg'
import IconCheck from '@/assets/svg/check.svg'
import IconCross from '@/assets/svg/cross.svg'
import WaveContentSyncStatusCell from '@/components/program/tables/WavesTable/WaveContentSyncStatusCell.vue'
import WaveIdCell from '@/components/program/tables/WavesTable/WaveIdCell.vue'
import WaveStatusCell from '@/components/program/tables/WavesTable/WaveStatusCell.vue'
import { ProgramsActions } from '@/vuex/modules/programs/actions'
import { ProgramsGetters } from '@/vuex/modules/programs/getters'
import { PROGRAMS_NAMESPACE } from '@/vuex/modules/programs/programs.state'
import moment from 'moment'
import { mapActions, mapGetters } from 'vuex'
import sortChangeMixin from './sortChangeMixin'

export default {
  name: 'WavesTable',
  components: {
    IconArchive,
    IconDuplicate,
    IconSync,
    IconCheck,
    IconCross
  },
  mixins: [ sortChangeMixin ],
  data () {
    return {
      waveDropdown: false,
      tableData: [],
      selectedRows: [],
      isArchivingWaves: false,
      isDuplicatingWaves: false,
      waveNumber: null,
      sortOption: {
        sortChange: (params) => this.sortChange(params)
      },
      tableCheckboxHandlers: {
        selectedRowKeys: [],
        selectedRowChange: ({ row, isSelected, selectedRowKeys }) => {
          this.selectedRows = selectedRowKeys
          this.tableCheckboxHandlers.selectedRowKeys = selectedRowKeys
        },
        selectedAllChange: ({ isSelected, selectedRowKeys }) => {
          this.selectedRows = selectedRowKeys
          this.tableCheckboxHandlers.selectedRowKeys = selectedRowKeys
        }
      },
      columns: [
        {
          field: '',
          key: 'checkbox',
          type: 'checkbox',
          title: '',
          width: 50,
          align: 'center'
        },
        {
          field: 'id',
          key: 'a',
          title: 'Wave',
          align: 'left',
          sortBy: '',
          width: '20%',
          renderBodyCell: ({ row, column, rowIndex }, h) => <WaveIdCell wave={row} />
        },
        {
          field: 'waveNumber',
          key: 'e',
          title: '#',
          align: 'left',
          sortBy: ''
        },
        {
          field: 'status',
          key: 'b',
          title: 'Status',
          align: 'left',
          sortBy: '',
          renderBodyCell: ({ row, column, rowIndex }, h) => <WaveStatusCell wave={row} />
        },
        { field: 'languageName', key: 'c', title: 'Language', align: 'left', sortBy: '' },
        {
          field: 'classroomDate',
          key: 'd',
          title: 'Classroom',
          align: 'center',
          sortBy: '',
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            if (row.classroomDate) {
              return moment(row.classroomDate).format('DD.MM.YYYY')
            }
            return 'Not set'
          }
        },
        { field: 'moduleCount', key: 'g', title: 'Modules', align: 'center', sortBy: '' },
        { field: 'participantCount', key: 'h', title: 'Participants', align: 'center', sortBy: '' },
        {
          field: 'contentSync',
          key: 'i',
          title: 'Sync',
          align: 'center',
          sortBy: '',
          renderBodyCell: ({ row, column, rowIndex }, h) => <WaveContentSyncStatusCell wave={row} />
        }
      ]
    }
  },
  created () {
    this.tableData = this.waves.slice()
    this.waveNumber = this.tableData[0]?.waveNumber
  },
  props: {
    waves: Array,
    program: Object
  },
  methods: {
    ...mapActions(PROGRAMS_NAMESPACE, {
      addWave: ProgramsActions.addWave,
      archiveWaves: ProgramsActions.archiveWaves,
      fetchProgramsList: ProgramsActions.fetchProgramsList,
      getUpdatedProgram: ProgramsActions.getUpdatedProgram,
      duplicateWaves: ProgramsActions.duplicateWave,
      updateWaveNumber: ProgramsActions.updateWaveNumber
    }),
    async duplicateWavesAction () {
      const { companyCode } = this.$route.params
      this.isDuplicatingWaves = true
      try {
        const waves = await this.duplicateWaves({
          companyCode,
          ongoingProgramId: this.selectedRows[0],
          program: this.$props.program
        })
        this.selectedRows = []
        this.tableCheckboxHandlers.selectedRowKeys = []
        const rowSelector = `.wave-table > div > div > div > table > tbody > tr.ve-table-body-tr[row-key="${waves.slice().pop().id}"]`
        const selector = `${rowSelector} > td`
        this.tableData = waves.slice()
        this.$nextTick(() => {
          window.document.querySelector(rowSelector)?.scrollIntoView()
          window.document.querySelectorAll(selector).forEach(i => {
            i.classList.add('hot')
          })
          setTimeout(() => {
            window.document.querySelectorAll(selector).forEach(i => {
              i.classList.remove('hot')
            })
          }, 1500)
        })
        this.setToast('Wave Duplicated')
      } catch (e) {
        this.setToast(e.message, true)
      } finally {
        this.isDuplicatingWaves = false
      }
    },
    setToast (txt, isError = false) {
      this.$bvToast.toast(txt, {
        title: isError ? 'Error' : 'Success',
        autoHideDelay: 2000,
        appendToast: true,
        variant: isError ? 'danger' : 'success'
      })
    },
    async archiveWavesAction () {
      const { companyCode } = this.$route.params
      this.isArchivingWaves = true
      return await this.archiveWaves({ companyCode, ongoingProgramIds: this.selectedRows, programId: this.$props.program.id })
        .then(response => {
          response.forEach(item => this.setToast(`WaveId ${item.id}: ${item.message}`, !item.success))
        })
        .catch(e => {
          this.setToast('Unable to archive!', true)
        })
        .finally(() => {
          this.isArchivingWaves = false
          this.selectedRows = []
          this.getUpdatedProgram({ companyCode, programId: this.$props.program.id })
            .then(waves => {
              this.tableData = waves.slice()
            })
        })
    },
    cancelEditWaveNumber () {
      this.waveNumber = this.tableData[0]?.waveNumber
    },
    async submitEditWaveNumber () {
      if (this.waveNumber !== this.tableData[0].waveNumber) {
        const { companyCode } = this.$route.params
        try {
          await this.updateWaveNumber({
            companyCode,
            waveId: this.$props.program.waves[0].id,
            newWaveNumber: parseInt(this.waveNumber),
            programId: this.$props.program.id
          })
        } catch (e) {
          this.waveNumber = this.$props.program.waves[0].waveNumber
        } finally {
          this.getUpdatedProgram({ companyCode, programId: this.$props.program.id })
            .then(waves => {
              this.tableData = waves.slice()
            })
        }
      } else {
        this.cancelEditWaveNumber()
      }
    }
  },
  computed: {
    ...mapGetters(PROGRAMS_NAMESPACE, {
      languages: ProgramsGetters.languages
    })
  }
}
</script>

<style lang="scss">

.wave-table > div > div >div >table > thead > tr > th {
    font-weight: 800 !important;
}
.wave-table > div > div >div >table > thead > tr > th > span.ve-table-sort{
    translate: 0 -25%;
}
.wave-table > div > div >div >table > thead > tr > th > label > span.ve-checkbox-content{
    translate: 0 25%;
}

.wave-table > div > div > div > table > tbody > tr.ve-table-body-tr > td {
  transition: background 1s;
}

.wave-table > div > div > div > table > tbody > tr.ve-table-body-tr > td.hot {
  background: lightseagreen !important;
  transition: background 1s;
}

</style>

<style scoped>

.wave-table {
  margin: 0 0 60px 0;
}

.action-bar {
  display: flex;
  justify-content: space-between;
  margin: 0;
}

.controls-bar-right {
  display: flex;
  align-items: flex-end;
  margin-left: 1em;
  padding: 5px;
  gap: 1rem;
}

.controls-bar-left {
  display: flex;
  align-items: stretch;
  margin-right: 1em;
  gap: 1rem;
}

.empty-table {
  display: flex;
  justify-content: center;
}

.wave-dropdown {
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
}

.wave-dropdown-placer {
  position: relative;
  height: 1px;
}

.wave-dropdown-menu {
  background: white;
  position: absolute;
  min-width: 100%;
  z-index: 9999;
  top: 0;
  right:0;
  margin-top: -1px;
}

.wave-number {
  margin: 0 auto;
  display: flex;
  align-items: center;
}

.input-number {
  width: 100%;
  max-width: 65px;
}

.icon-controls {
  display: flex;
  align-items: flex-end;
}

.icon {
  box-sizing: border-box;
  width: 16px;
  height: 16px;
  margin-left:8px;
  cursor: pointer;
}

svg.icon-cross > path {
  fill: #b61212 !important;
}
</style>
